@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.71258f66.eot);
  src:  url(/static/media/icomoon.71258f66.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.7744584a.ttf) format('truetype'),
    url(/static/media/icomoon.5705f211.woff) format('woff'),
    url(/static/media/icomoon.57cc80f5.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email:before {
  content: "\e945";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-github:before {
  content: "\eab0";
}
.icon-linkedin:before {
  content: "\eaca";
}

html, body, p, a, span {
    font-family: Montserrat, Helvetica, Arial
}

body {
    margin: 0;
    background-color: #FCFCFC;
    color: #14213d;
}

h1, h2, h3, h4, h5 {
    font-weight: 400;
}

a {
    color: #3d5eac;
    text-decoration: none;
    font-weight: 300;
}

a:hover {
    -webkit-transition: color 0.4s ease;
    transition: color 0.4s ease;
    color: #2274a5;
}

ul li a {
    color: #14213d;
}

ul li a:hover {
    color: #3d5eac;
}

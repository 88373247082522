@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');

html, body, p, a, span {
    font-family: Montserrat, Helvetica, Arial
}

body {
    margin: 0;
    background-color: #FCFCFC;
    color: #14213d;
}

h1, h2, h3, h4, h5 {
    font-weight: 400;
}

a {
    color: #3d5eac;
    text-decoration: none;
    font-weight: 300;
}

a:hover {
    transition: color 0.4s ease;
    color: #2274a5;
}

ul li a {
    color: #14213d;
}

ul li a:hover {
    color: #3d5eac;
}